<template>
  <v-card tile elevation="0" class="item-card pa-0 item-card-backcolor">
    <v-card-text class="pa-0">
      <div class="pt-2">
        <slot name="item_edit"></slot>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ["com_data"],
  setup(props, ctx) {
    return {
      props,
    };
  },
};
</script>
