import Repository from "../repository";

const resource = "/schedules";

export default {
  list(payload) {
    return Repository.get(`${resource}/list`, { params: payload });
  },
  list_with_prop(payload) {
    return Repository.get(`${resource}/list_with_prop`, payload);
  },
  get(scheduleId) {
    return Repository.get(`${resource}/get/${scheduleId}`);
  },
  get_with_prop(payload) {
    return Repository.get(`${resource}/get_with_prop`, payload);
  },
  create(payload) {
    return Repository.post(`${resource}/create`, payload);
  },
  update(payload) {
    return Repository.put(`${resource}/update`, payload);
  },
  delete(scheduleId) {
    return Repository.delete(`${resource}/delete/${scheduleId}`);
  },
  all_clear(payload) {
    return Repository.post(`${resource}/all_clear`, payload);
  },
};
