import Repository from "../repository";

const resource = "/working_shops";

export default {
  get(workingShopId) {
    return Repository.get(`${resource}/get/${workingShopId}`);
  },
  get_by_shopid(ShopId) {
    return Repository.get(`${resource}/get_by_shopid/${ShopId}`);
  },
  // create(payload) {
  //   return Repository.post(`${resource}/create`, payload);
  // },
  // update(payload) {
  //   return Repository.put(`${resource}/update`, payload);
  // },
  // delete(workingShopId) {
  //   return Repository.delete(`${resource}/delete/${workingShopId}`);
  // },
  save(payload) {
    return Repository.post(`${resource}/save`, payload);
  },
};
