import Repository from "../repository";

const resource = "/content_formats";

export default {
  get(contentFormatId) {
    return Repository.get(`${resource}/get/${contentFormatId}`);
  },
  list() {
    return Repository.get(`${resource}/list`);
  },
  // create(payload) {
  //   return Repository.post(`${resource}/create`, payload);
  // },
  // update(payload) {
  //   return Repository.put(`${resource}/update`, payload);
  // },
  // delete(templateId) {
  //   return Repository.delete(`${resource}/delete/${templateId}`);
  // },
};
