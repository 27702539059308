const state = {
  id: 0,
  loginid: "",
  name: "",
  email: "",
  role: "",
};

const getters = {
  allState: (state) => state,
};

const actions = {
  setProvider({ commit }, value) {
    commit("setProvider", value);
  },
  changeProviderName({ commit }, value) {
    commit("setProviderName", value);
  },
  unsetProvider({ commit }) {
    commit("unsetProvider");
  },
};

const mutations = {
  setProvider(state, payload) {
    state.id = payload.id;
    state.loginid = payload.loginid;
    state.name = payload.name;
    state.email = payload.email;
    state.role = payload.role;
  },
  setProviderName(state, value) {
    state.name = value;
  },
  unsetProvider(state) {
    state.id = 0;
    state.loginid = "";
    state.name = "";
    state.email = "";
    state.role = "";
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
