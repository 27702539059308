import axios from "axios";
import store from "@/store/index.js";
import router from "@/router";

const baseDomain =
  process.env.VUE_APP_API_HTTPS +
  process.env.VUE_APP_API_DOMAIN +
  process.env.VUE_APP_API_BASE_URL;
const baseURL = `${baseDomain}/`;

const repository = axios.create({
  baseURL,
  withCredentials: true,
  //  headers: store.getters["auth/addHeader"],
});

// axios前処理
repository.interceptors.request.use((request) => {
  // 認証用
  if (store.getters["auth/addHeader"]) {
    request.headers["Authorization"] = store.getters["auth/addHeader"];
  }
  return request;
});

// axios後処理
repository.interceptors.response.use(
  // 2XX範囲内のステータスコード
  (response) => {
    // performance.mark("finish");
    // performance.measure("request-to-response", "start", "finish");
    // const message = `${
    //   performance.getEntriesByName("request-to-response")[0].duration
    // }`;
    // console.log(message);
    // Sentryでログを残すもよし
    // Sentry.captureMessage(message, Sentry.Severity.Debug);
    store.dispatch(
      "system/setVersion",
      response.headers["yatoite-system-version"]
    );
    return response;
  },
  // 2XX範囲外のステータスコード
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          // console.log("ユーザー認証失敗[Repositorys.js]");
          if (
            error.config.url == "/users/login" ||
            error.config.url == "/providers/login"
          ) {
            // ログイン画面の場合のみ、エラーにしない。
            return false;
          } else {
            let pushName = null;
            if (store.getters["auth/getType"] == "admin") {
              alert("サーバの認証に失敗しました。ログイン画面に戻ります。");
              pushName = "admin-login";
            } else if (store.getters["auth/getType"] == "user") {
              alert("サーバの認証に失敗しました。ログイン画面に戻ります。");
              pushName = "login";
            }
            console.log("認証NG", store.getters["auth/getType"]);
            store.dispatch("auth/logoutUser");
            if (pushName) router.push({ name: pushName });
            return Promise.reject(error);
          }
        case 404:
          console.log("取得データなし");
          return Promise.reject(error);
        default:
          alert(
            "サーバとの通信でエラーが発生しました。\nREPSONCE CODE : " +
              error.response.status +
              " " +
              error.response.statusText
          );
          store.dispatch("loadingIcon/hideIcon"); // ローディング非表示
      }
    } else {
      alert("サーバにて正しく処理できませんでした。再度実行してください。");
      store.dispatch("loadingIcon/hideIcon"); // ローディング非表示
      console.log(error);
    }
    return Promise.reject(error);
  }
);

export default repository;
