// ボタンコンポーネント
<template>
  <v-btn
    :class="set_class"
    :color="btn_color"
    :block="set_block"
    :loading="set_loading"
    :disabled="set_disabled"
    :depressed="set_depressed"
    :outlined="set_outlined"
    :text="set_text"
    @click="onClick"
    ><slot name="icon"></slot><slot></slot
  ></v-btn>
</template>

<script>
import { toRefs, watch } from "@vue/composition-api";

export default {
  props: {
    btn_color: {
      type: String,
      default: "",
    },
    btn_type: {
      type: String,
    },
    btn_block: {
      type: Boolean,
      default: false,
    },
    btn_loading: {
      type: Boolean,
      default: false,
    },
    btn_disabled: {
      type: Boolean,
      default: false,
    },
    btn_depressed: {
      type: Boolean,
      default: false,
    },
    btn_outlined: {
      type: Boolean,
      default: false,
    },
    btn_text: {
      type: Boolean,
      default: false,
    },
    add_class: {
      type: String,
      default: "",
    },
  },
  setup(props, context) {
    const state = toRefs(props);

    let set_class = "";
    let set_color = state.btn_color;
    let set_block = state.btn_block;
    let set_loading = state.btn_loading;
    let set_disabled = state.btn_disabled;
    let set_depressed = state.btn_depressed;
    let set_outlined = state.btn_outlined;
    let set_text = state.btn_text;

    const custom_class = () => {
      const default_class = "font-weight-bold";
      let res = default_class;
      if (state.btn_type.value) {
        res += " " + state.btn_type.value;
      }
      res += " " + state.add_class.value;
      return res;
    };
    set_class = custom_class();

    watch(state.btn_type, () => {
      set_class = custom_class();
    });
    watch(state.btn_color, () => {
      set_color = state.btn_color;
    });
    watch(state.btn_block, () => {
      set_block = state.btn_block;
    });
    watch(state.btn_loading, () => {
      set_loading = state.btn_loading;
      state.btn_disabled = state.btn_loading;
    });
    watch(state.btn_disabled, () => {
      set_disabled = state.btn_disabled;
    });
    watch(state.btn_depressed, () => {
      set_depressed = state.btn_depressed;
    });
    watch(state.btn_outlined, () => {
      set_outlined = state.btn_outlined;
    });
    watch(state.btn_text, () => {
      set_text = state.btn_text;
    });

    const onClick = () => {
      // クリックイベントを親コンポーネントに渡す
      context.emit("click-event");
    };

    return {
      set_class,
      set_color,
      set_block,
      set_loading,
      set_disabled,
      set_depressed,
      set_outlined,
      set_text,
      onClick,
    };
  },
};
</script>
