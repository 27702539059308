const state = {
  castList: [],
};

const getters = {
  getCastList: (state) => state.castList,
  getCastById: (state) => (id) => {
    const cast = state.castList.find((r) => r.id == id);
    return cast;
  },
};

const actions = {
  createCast({ commit }, value) {
    commit("createCast", value);
  },
  updateCast({ commit }, value) {
    commit("updateCast", value);
  },
  deleteCast({ commit }, cast_id) {
    commit("deleteCast", cast_id);
  },
  setCastList({ commit }, value) {
    commit("setCastList", value);
  },
  clear({ commit }) {
    commit("clear");
  },
};

const mutations = {
  // キャスト追加
  createCast(state, value) {
    state.castList.push(value);
  },
  // キャスト更新
  updateCast(state, value) {
    state.castList.forEach(function (cast, index) {
      if (value.id == cast.id) {
        state.castList.splice(index, 1, value);
      }
    });
  },
  // キャストを削除する
  deleteCast(state, cast_id) {
    state.castList.forEach(function (cast, index) {
      if (cast_id == cast.id) {
        state.castList.splice(index, 1);
      }
    });
  },
  // キャストの一覧を返す
  setCastList(state, array) {
    state.castList = array;
  },
  clear(state) {
    state.castList = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
