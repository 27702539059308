import Repository from "../repository";

const resource = "/schedule_patterns";

export default {
  cast_pattern_save(payload) {
    return Repository.post(`${resource}/cast_pattern_save`, payload);
  },
  apply_pattern(payload) {
    return Repository.post(`${resource}/apply_pattern`, payload);
  },
  schedule_to_pattern(payload) {
    return Repository.post(`${resource}/schedule_to_pattern`, payload);
  },
  cast_pattern(castId) {
    return Repository.get(`${resource}/cast_pattern/${castId}`);
  },
  get(schedulePatternId) {
    return Repository.get(`${resource}/cast_pattern/${schedulePatternId}`);
  },
  create(payload) {
    return Repository.post(`${resource}/create`, payload);
  },
  update(payload) {
    return Repository.put(`${resource}/update`, payload);
  },
  delete(schedulePatternId) {
    return Repository.delete(`${resource}/delete/${schedulePatternId}`);
  },
};
