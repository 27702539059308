const state = {
  currentDay: 0,
  weekBtn: {
    prev: false,
    today: false,
    next: true,
  },
};

const getters = {
  getCurrentDay: (state) => state.currentDay,
  getWeekBtn: (state) => state.weekBtn,
};

const actions = {
  setCurrentDay({ commit }, day) {
    commit("setDay", day);
  },
};

const mutations = {
  setDay(state, day) {
    state.currentDay = day;
    if (day == 0) {
      state.weekBtn.prev = false;
      state.weekBtn.today = false;
    }
    if (day >= 7) {
      state.weekBtn.prev = true;
      state.weekBtn.today = true;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
