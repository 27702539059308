// ページの情報に関するデータを保持

const state = {
  title: "",
  description: "",
};

const getters = {
  allState: (state) => state,
};

const actions = {
  setPageMeta({ commit }, value) {
    commit("setPageMeta", value);
  },
};

const mutations = {
  setPageMeta(state, payload) {
    state.title = payload.title;
    state.description = payload.description;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
