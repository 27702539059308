import Repository from "../repository";

const resource = "/working_casts";

export default {
  // get(templateId) {
  //   return Repository.get(`${resource}/get/${templateId}`);
  // },
  list(payload) {
    return Repository.post(`${resource}/list`, payload);
  },
  // create(payload) {
  //   return Repository.post(`${resource}/create`, payload);
  // },
  update(payload) {
    return Repository.put(`${resource}/update`, payload);
  },
  // delete(templateId) {
  //   return Repository.delete(`${resource}/delete/${templateId}`);
  // },
  save(payload) {
    return Repository.post(`${resource}/save`, payload);
  },
};
