import Vue from "vue";
import VueRouter from "vue-router";

// store
import store from "@/store";

const DEFAULT_TITLE = process.env.VUE_APP_TITLE;

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "home",
  },
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/user/home/index.vue"),
    meta: {
      title: "ホーム",
      description: "",
      requiresAuth: true,
    },
  },
  {
    path: "/cast",
    name: "cast",
    component: () => import("@/views/user/cast/index.vue"),
    meta: {
      title: "キャスト管理",
      description: "",
      requiresAuth: true,
    },
  },
  {
    path: "/cast-edit/:cast_id",
    name: "cast-edit",
    component: () => import("@/views/user/castEdit/index.vue"),
    props: true,
    meta: {
      title: "キャスト管理 プロフィール編集",
      description: "",
      requiresAuth: true,
    },
  },
  {
    path: "/cast-update",
    name: "cast-update",
    component: () => import("@/views/user/castUpdate/index.vue"),
    meta: {
      title: "キャスト管理 一括更新",
      description: "",
      requiresAuth: true,
    },
  },
  {
    path: "/cast-sort",
    name: "cast-sort",
    component: () => import("@/views/user/castSort/index.vue"),
    meta: {
      title: "キャスト管理 並び替え",
      description: "",
      requiresAuth: true,
    },
  },
  {
    path: "/schedule",
    name: "schedule",
    component: () => import("@/views/user/schedule/index.vue"),
    meta: {
      title: "スケジュール管理",
      description: "",
      requiresAuth: true,
    },
  },
  {
    path: "/synchro-contents",
    name: "synchro-contents",
    component: () => import("@/views/user/synchroContents/index.vue"),
    meta: {
      title: "更新コンテンツ",
      description: "",
      requiresAuth: true,
    },
  },
  {
    path: "/synchro-contents-edit/:update_content_id/:shop_site_id/:site_content_id",
    name: "synchro-contents-edit",
    component: () => import("@/views/user/synchroContentsEdit/index.vue"),
    props: true,
    meta: {
      title: "更新コンテンツ",
      description: "",
      requiresAuth: true,
    },
  },
  {
    path: "/synchro-contents-add/:shop_site_id/:site_content_id",
    name: "synchro-contents-add",
    component: () => import("@/views/user/synchroContentsEdit/index.vue"),
    props: true,
    meta: {
      title: "更新コンテンツ",
      description: "",
      requiresAuth: true,
    },
  },
  {
    path: "/template",
    name: "template",
    component: () => import("@/views/user/template/index.vue"),
    meta: {
      title: "テンプレート管理",
      description: "",
      requiresAuth: true,
    },
  },
  {
    path: "/working",
    name: "working",
    component: () => import("@/views/user/working/index.vue"),
    meta: {
      title: "勤務ステータス更新",
      description: "",
      requiresAuth: true,
    },
  },
  {
    path: "/castup",
    name: "castup",
    component: () => import("@/views/user/castup/index.vue"),
    meta: {
      title: "キャスト上位更新",
      description: "",
      requiresAuth: true,
    },
  },
  {
    path: "/hp-link",
    name: "hp-link",
    component: () => import("@/views/user/hpLink/index.vue"),
    meta: {
      title: "ホームページ連携",
      description: "",
      requiresAuth: true,
    },
  },
  {
    path: "/result-log",
    name: "result-log",
    component: () => import("@/views/user/resultLog/index.vue"),
    meta: {
      title: "更新結果履歴",
      description: "",
      requiresAuth: true,
    },
  },
  {
    path: "/setting-synchro",
    name: "setting-synchro",
    component: () => import("@/views/user/settingSynchro/index.vue"),
    meta: {
      title: "媒体管理",
      description: "",
      requiresAuth: true,
    },
  },
  {
    path: "/setting-shop",
    name: "setting-shop",
    component: () => import("@/views/user/settingShop/index.vue"),
    meta: {
      title: "店舗管理",
      description: "",
      requiresAuth: true,
    },
  },
  {
    path: "/setting-basic",
    name: "setting-basic",
    component: () => import("@/views/user/settingBasic/index.vue"),
    meta: {
      title: "ユーザー設定",
      description: "",
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/user/login.vue"),
    meta: {
      title: "ログイン | " + DEFAULT_TITLE,
      description: "",
      layout: "blank",
    },
  },
  {
    path: "/impersonate/:loginid/:token",
    name: "impersonate",
    component: () => import("@/views/user/login.vue"),
    props: true,
    meta: {
      title: "ログイン | " + DEFAULT_TITLE,
      description: "",
      layout: "blank",
    },
  },
  {
    path: "/admin/login",
    name: "admin-login",
    component: () => import("@/views/admin/login.vue"),
    meta: {
      title: "管理者ログイン | " + DEFAULT_TITLE,
      description: "",
      layout: "blank",
    },
  },
  {
    path: "/admin/home",
    name: "admin-home",
    component: () => import("@/views/admin/home/index.vue"),
    meta: {
      title: "管理者ホーム",
      description: "",
      requiresAuth: true,
      layout: "admin",
    },
  },
  {
    path: "/admin/user",
    name: "admin-user",
    component: () => import("@/views/admin/user/index.vue"),
    meta: {
      title: "ユーザー管理",
      description: "",
      requiresAuth: true,
      layout: "admin",
    },
  },
  {
    path: "/admin/shop",
    name: "admin-shop",
    component: () => import("@/views/admin/shop/index.vue"),
    meta: {
      title: "店舗管理",
      description: "",
      requiresAuth: true,
      layout: "admin",
    },
  },
  {
    path: "/admin/contract",
    name: "admin-contract",
    component: () => import("@/views/admin/contract/index.vue"),
    meta: {
      title: "契約管理",
      description: "",
      requiresAuth: true,
      layout: "admin",
    },
  },
  {
    path: "/admin/contract-edit/:shop_id",
    name: "admin-contract-edit",
    component: () => import("@/views/admin/contractEdit/index.vue"),
    props: true,
    meta: {
      title: "契約管理 編集",
      description: "",
      requiresAuth: true,
      layout: "admin",
    },
  },
  {
    path: "/admin/information",
    name: "admin-information",
    component: () => import("@/views/admin/information/index.vue"),
    meta: {
      title: "お知らせ管理",
      description: "",
      requiresAuth: true,
      layout: "admin",
    },
  },
  {
    path: "/admin/sales",
    name: "admin-sales",
    component: () => import("@/views/admin/sales/index.vue"),
    meta: {
      title: "月別契約一覧",
      description: "",
      requiresAuth: true,
      layout: "admin",
    },
  },
  {
    path: "/admin/billing",
    name: "admin-billing",
    component: () => import("@/views/admin/billing/index.vue"),
    meta: {
      title: "請求管理",
      description: "",
      requiresAuth: true,
      layout: "admin",
    },
  },
  {
    path: "/admin/closing",
    name: "admin-closing",
    component: () => import("@/views/admin/closing/index.vue"),
    meta: {
      title: "締め日管理",
      description: "",
      requiresAuth: true,
      layout: "admin",
    },
  },
  {
    path: "/admin/setting-synchro/:shop_id",
    name: "admin-setting-synchro",
    component: () => import("@/views/admin/settingSynchro/index.vue"),
    props: true,
    meta: {
      title: "媒体管理",
      description: "",
      requiresAuth: true,
      layout: "admin",
    },
  },
  {
    path: "/admin/status",
    name: "admin-status",
    component: () => import("@/views/admin/status/index.vue"),
    meta: {
      title: "ステータス管理",
      description: "",
      requiresAuth: true,
      layout: "admin",
    },
  },
  {
    path: "/error-404",
    name: "error-404",
    component: () => import("@/views/error.vue"),
    props: { page_title: "Error" },
    meta: {
      layout: "blank",
    },
  },
  {
    path: "*",
    redirect: "error-404",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !store.getters["auth/isLogined"]
  ) {
    if (from.name != "login") {
      next({ name: "login" });
    }
  } else if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    store.getters["auth/isLogined"]
  ) {
    var auth_type = store.getters["auth/getType"];
    if (auth_type == "user") {
      if (to.path.includes("/admin")) {
        alert("権限がありません。");
        next({ name: "home" });
      }
    }
    if (auth_type == "admin") {
      if (!to.path.includes("/admin")) {
        alert("ユーザーでログインして下さい。");
        next({ name: "admin-home" });
      }
    }
  }
  next();
});

router.afterEach((to, from) => {
  document.title = to.meta.title || DEFAULT_TITLE;
  store.dispatch("page_meta/setPageMeta", to.meta);
});

export default router;
