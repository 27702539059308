// 必須入力ラベル
<template>
  <div class="item-req" v-if="isRequired">
    <div>必須項目</div>
    <div>{{ item_name }}</div>
  </div>
</template>

<script>
import setting from "@/common/setting.js";
export default {
  props: ["item_rules", "item_key"],
  setup(props, ctx) {
    const item_name = setting.cast_props_common[props.item_key].text;
    let isRequired = false;
    if (props.item_key in props.item_rules) {
      isRequired = props.item_rules[props.item_key].is_required;
    }
    return {
      item_name,
      isRequired,
    };
  },
};
</script>
