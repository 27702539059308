// カードコンポーネント
<template>
  <v-card
    class="mx-auto mb-3"
    elevation="0"
    max-width="1200"
    :loading="set_loading"
    :disabled="set_disabled"
    ><slot></slot
  ></v-card>
</template>

<script>
import { toRefs, watch } from "@vue/composition-api";

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const state = toRefs(props);

    let set_loading = state.loading;
    let set_disabled = state.disabled;

    watch(state.disabled, () => {
      set_disabled = state.disabled;
    });
    watch(state.loading, () => {
      set_loading = state.loading;
      state.disabled = state.loading;
    });

    return {
      set_loading,
      set_disabled,
    };
  },
};
</script>
