import Repository from "../repository";

const resource = "/unclaimed_contracts";

export default {
  admin_get(billId) {
    return Repository.get(`${resource}/admin/get/${billId}`);
  },
  admin_create(payload) {
    return Repository.post(`${resource}/admin/create`, payload);
  },
  admin_update(payload) {
    return Repository.put(`${resource}/admin/update`, payload);
  },
  admin_delete(billId) {
    return Repository.delete(`${resource}/admin/delete/${billId}`);
  },
  admin_save(payload) {
    return Repository.post(`${resource}/admin/save`, payload);
  },
};
