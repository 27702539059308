<template>
  <div>
    <ckeditor v-model="editorData" :config="editorConfig"></ckeditor>
  </div>
</template>

<script>
import { toRefs, reactive } from "@vue/composition-api";
import CKEditor from "ckeditor4-vue";

export default {
  props: ["data"],
  components: {
    // Use the <ckeditor> component in this view.
    ckeditor: CKEditor.component,
  },
  setup(props, context) {
    const state = reactive({
      editorData: "",
    });

    state.editorData = props.data;

    return {
      ...toRefs(state),
      editorConfig: {
        versionCheck: false,
        height: 250, //317,
        allowedContent: true,
        pasteFromWordRemoveFontStyles: false,
        toolbarGroups: [
          { name: "document", groups: ["mode", "document", "doctools"] },
          // { name: "clipboard", groups: ["clipboard", "undo"] },
          {
            name: "editing",
            groups: ["find", "selection", "spellchecker", "editing"],
          },
          { name: "links", groups: ["links"] },
          { name: "insert", groups: ["insert"] },
          { name: "forms", groups: ["forms"] },
          { name: "tools", groups: ["tools"] },
          { name: "others", groups: ["others"] },
          { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
          {
            name: "paragraph",
            groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"],
          },
          { name: "styles", groups: ["styles"] },
          { name: "colors", groups: ["colors"] },
        ],
        extraPlugins: ["preview", "justify", "font", "colorbutton"],
        removeButtons:
          "Underline,Subscript,Superscript,Undo,Redo,PasteFromWord,Maximize,PasteText,Scayt,Anchor,About,NumberedList,BulletedList,Outdent,Indent,Table,Blockquote,Styles",
      },
    };
  },
};
</script>
