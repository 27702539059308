import Repository from "../repository";

const resource = "/shop_contracts";

export default {
  admin_list(payload) {
    return Repository.get(`${resource}/admin/list`, { params: payload });
  },
  admin_get(shopContractId) {
    return Repository.get(`${resource}/admin/get/${shopContractId}`);
  },
  admin_create(payload) {
    return Repository.post(`${resource}/admin/create`, payload);
  },
  admin_update(payload) {
    return Repository.put(`${resource}/admin/update`, payload);
  },
  admin_delete(shopContractId) {
    return Repository.delete(`${resource}/admin/delete/${shopContractId}`);
  },
  admin_get_with_details(shopContractId) {
    return Repository.get(
      `${resource}/admin/get_with_details/${shopContractId}`
    );
  },
  admin_list_by_date(payload) {
    return Repository.get(`${resource}/admin/list_by_date`, {
      params: payload,
    });
  },
  admin_check(payload) {
    return Repository.post(`${resource}/admin/check`, payload);
  },
  admin_sales(payload) {
    return Repository.get(`${resource}/admin/sales`, { params: payload });
  },
  admin_sales_user(payload) {
    return Repository.get(`${resource}/admin/sales_user`, { params: payload });
  },
};
