import Repository from "../repository";

const resource = "/bills";

export default {
  admin_get(billId) {
    return Repository.get(`${resource}/admin/get/${billId}`);
  },
  admin_billing_list(payload) {
    return Repository.post(`${resource}/admin/billing_list`, payload);
  },
  admin_billing_list_summary(payload) {
    return Repository.post(`${resource}/admin/billing_list_summary`, payload);
  },
  admin_create(payload) {
    return Repository.post(`${resource}/admin/create`, payload);
  },
  admin_update(payload) {
    return Repository.put(`${resource}/admin/update`, payload);
  },
  admin_delete(billId) {
    return Repository.delete(`${resource}/admin/delete/${billId}`);
  },
  admin_delete_all(payload) {
    return Repository.post(`${resource}/admin/delete_all`, payload);
  },
  admin_make_bill(payload) {
    return Repository.post(`${resource}/admin/make_bill`, payload);
  },
};
