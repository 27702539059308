import Repository from "../repository";

const resource = "/cast_images";

export default {
  save_all(payload) {
    return Repository.post(`${resource}/save_all`, payload);
  },
  list_cast(payload) {
    return Repository.get(`${resource}/list_cast`, { params: payload });
  },
  // get(castImageId) {
  //   return Repository.get(`${resource}/get/${castImageId}`);
  // },
  // get_all(payload) {
  //   return Repository.post(`${resource}/get_all`, payload);
  // },
  // create(payload) {
  //   return Repository.post(`${resource}/create`, payload);
  // },
  // update(payload) {
  //   return Repository.put(`${resource}/update`, payload);
  // },
  // delete(castImageId) {
  //   return Repository.delete(`${resource}/delete/${castImageId}`);
  // },
};
