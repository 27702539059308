import Repository from "../repository";

const resource = "/system_props";

export default {
  admin_get_key(key) {
    return Repository.get(`${resource}/admin/get_by_key/${key}`);
  },
  admin_update_key(payload) {
    return Repository.put(`${resource}/admin/update_key`, payload);
  },
  // create(payload) {
  //   return Repository.post(`${resource}/create`, payload);
  // },
  // delete(castPropId) {
  //   return Repository.delete(`${resource}/delete/${castPropId}`);
  // },
};
