import Repository from "../repository";

const resource = "/child_update_contents";

export default {
  get(childUpdateContentId) {
    return Repository.get(`${resource}/get/${childUpdateContentId}`);
  },
  list(payload) {
    return Repository.post(`${resource}/list`, payload);
  },
  save(payload) {
    return Repository.post(`${resource}/save`, payload);
  },
  deletelist(payload) {
    return Repository.post(`${resource}/delete_list`, payload);
  },
  // create(payload) {
  //   return Repository.post(`${resource}/create`, payload);
  // },
  // update(payload) {
  //   return Repository.put(`${resource}/update`, payload);
  // },
  // delete(childUpdateContentsId) {
  //   return Repository.delete(`${resource}/delete/${childUpdateContentsId}`);
  // },
};
