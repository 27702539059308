import Repository from "../repository";

const resource = "/sites";

export default {
  list() {
    return Repository.get(`${resource}/list`);
  },
  list_with_content() {
    return Repository.get(`${resource}/list_with_content`);
  },
  get(siteId) {
    return Repository.get(`${resource}/get/${siteId}`);
  },
  get_with_content(siteId) {
    return Repository.get(`${resource}/get_with_content/${siteId}`);
  },
  admin_list_with_content() {
    return Repository.get(`${resource}/admin/list_with_content`);
  },
  admin_get_with_content(siteId) {
    return Repository.get(`${resource}/admin/get_with_content/${siteId}`);
  },
};
