import Repository from "../repository";

const resource = "/users";

export default {
  login(payload) {
    return Repository.post(`${resource}/login`, payload);
  },
  impersonate(payload) {
    return Repository.post(`${resource}/impersonate`, payload);
  },
  get(userId) {
    return Repository.get(`${resource}/get/${userId}`);
  },
  get_shop_site_all(userId) {
    return Repository.get(`${resource}/get_shop_site_all/${userId}`);
  },
  admin_get(userId) {
    return Repository.get(`${resource}/admin/get/${userId}`);
  },
  admin_list() {
    return Repository.get(`${resource}/admin/list`);
  },
  admin_create(payload) {
    return Repository.post(`${resource}/admin/create`, payload);
  },
  admin_update(payload) {
    return Repository.put(`${resource}/admin/update`, payload);
  },
  admin_delete(userId) {
    return Repository.delete(`${resource}/admin/delete/${userId}`);
  },
  admin_get_jwt(loginId) {
    return Repository.get(`${resource}/admin/get_jwt/${loginId}`);
  },
};
