<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="260"
    class="app-navigation-menu nav_border"
    :right="$vuetify.rtl"
    @input="(val) => $emit('update:is-drawer-open', val)"
  >
    <v-list>
      <v-list-item class="px-2">
        <v-img max-width="60%" src="@/assets/images/yatoite-logo.svg"></v-img
        ><span class="mini">【管理者用】</span>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-list nav dense>
      <template v-for="menu in menus">
        <v-list-item
          v-if="!menu.child_menu"
          :key="menu.name"
          :to="menu.to"
          @click="menu_click(menu.action)"
          link
          :disabled="loading"
        >
          <v-list-item-icon>
            <v-icon>{{ menu.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ menu.title }}</v-list-item-title>
        </v-list-item>
        <v-list-group
          v-else
          no-action
          v-model="menu.active"
          :key="menu.name"
          :prepend-icon="menu.icon"
        >
          <template v-slot:activator>
            <v-list-item-title>{{ menu.title }}</v-list-item-title>
          </template>
          <v-list-item
            v-for="c_menu in menu.child_menu"
            :key="c_menu.title"
            :to="c_menu.to"
            @click="menu_click(menu.action)"
            link
            :disabled="loading"
          >
            <v-list-item-title>
              {{ c_menu.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list-group>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import {
  reactive,
  toRefs,
  defineComponent,
  watch,
  ref,
  computed,
} from "@vue/composition-api";
import { useRouter } from "@/utils";
import store from "@/store";
import modelShopSite from "@/model/shopSite.js";

export default defineComponent({
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  setup(_, ctx) {
    const { router } = useRouter();
    let loading = ref(store.getters["loadingIcon/isLoading"].loading);

    const menus = [
      {
        title: "ホーム",
        icon: "mdi-home",
        to: "/admin/home",
        action: "group_close",
      },
      {
        title: "ユーザー管理",
        icon: "mdi-account",
        to: "/admin/user",
        action: "group_close",
      },
      {
        title: "店舗管理",
        icon: "mdi-store",
        to: "/admin/shop",
        action: "group_close",
      },
      {
        title: "契約管理",
        icon: "mdi-file-sign",
        to: "/admin/contract",
        action: "group_close",
      },
      {
        title: "請求管理",
        icon: "mdi-receipt-text-outline",
        to: "/admin/billing",
        action: "group_close",
      },
      {
        title: "締め日管理",
        icon: "mdi-calendar-lock",
        to: "/admin/closing",
        action: "group_close",
      },
      {
        title: "お知らせ管理",
        icon: "mdi-information-slab-circle-outline",
        to: "/admin/information",
        action: "group_close",
      },
      {
        title: "更新ステータス",
        icon: "mdi-list-status",
        to: "/admin/status",
        action: "group_close",
      },
      {
        title: "ログアウト",
        icon: "mdi-logout",
        to: "",
        action: "logout",
      },
    ];

    // メニューをクリックした時
    const menu_click = async (menu_action) => {
      switch (menu_action) {
        case "group_close":
          menus.forEach((menus) => (menus.active = false));
          break;
        case "logout":
          if (window.confirm("ログアウトしますか？")) {
            router.push({ name: "admin-login" });
          }
      }
    };

    // ローディングアイコン表示
    watch(store.getters["loadingIcon/isLoading"], (state) => {
      loading.value = state.loading;
    });

    return {
      menu_click,
      menus,
      loading,
    };
  },
});
</script>

<style scoped>
.nav_border {
  border-right: 1px solid #eeeeee;
}
</style>
