import Repository from "../repository";

const resource = "/shop_site_api_keys";

export default {
  admin_delete_hp_shopsiteid(shopSiteId) {
    return Repository.delete(
      `${resource}/admin/delete_hp_shopsiteid/${shopSiteId}`
    );
  },
  admin_get_key_type_hp(shopSiteId) {
    return Repository.get(`${resource}/admin/get_key_type_hp/${shopSiteId}`);
  },
};
