const state = {
  selected_site_id: 0,
  selected_shop_site_id: 0,
  cast_list: [],
  selected_cast_list: [],
  site_only_items: [],
};

const getters = {
  getSelectedSiteId: (state) => state.selected_site_id,
  getSelectedShopSiteId: (state) => state.selected_shop_site_id,
  getCastList: (state) => state.cast_list,
  getSelectedCastList: (state) => state.selected_cast_list,
  getSiteOnlyItems: (state) => state.site_only_items,
};

const actions = {
  setSiteId({ commit }, value) {
    commit("setSiteId", value);
  },
  setShopSiteId({ commit }, value) {
    commit("setShopSiteId", value);
  },
  setCastList({ commit }, value) {
    commit("setCastList", value);
  },
  setSelectedCastList({ commit }, value) {
    commit("setSelectedCastList", value);
  },
  setSiteOnlyItems({ commit }, value) {
    commit("setSiteOnlyItems", value);
  },
  clear({ commit }) {
    commit("clear");
  },
  clearSelectedCastList({ commit }) {
    commit("clearSelectedCastList");
  },
};

const mutations = {
  setSiteId(state, id) {
    state.selected_site_id = id;
  },
  setShopSiteId(state, id) {
    state.selected_shop_site_id = id;
  },
  setCastList(state, array) {
    state.cast_list = array;
  },
  setSelectedCastList(state, array) {
    state.selected_cast_list = array;
  },
  setSiteOnlyItems(state, value) {
    state.site_only_items = value;
  },
  clear(state) {
    state.selected_site_id = 0;
    state.selected_shop_site_id = 0;
    state.cast_list = [];
    state.selected_cast_list = [];
    state.site_only_items = [];
  },
  clearSelectedCastList({ commit }) {
    state.selected_cast_list = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
