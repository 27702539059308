import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/css/swiper.css";

import App from "@/app.vue";
import router from "@/router";
import store from "@/store";

import vuetify from "@/plugins/vuetify";

import "@/plugins/validate";
import "@/plugins/vue-composition-api";
// import CKEditor from "ckeditor4-vue";

Vue.config.productionTip = false;

Vue.use(VueAxios, axios);
Vue.use(VueAwesomeSwiper);
// Vue.use(CKEditor);

// 全体のエラー処理（consoleに表示）
Vue.config.errorHandler = function (err, vm, info) {
  // ローディング非表示
  store.dispatch("loadingIcon/hideIcon");
  if (process.env.NODE_ENV !== "production") {
    console.log("【例外】ERROR:01 -> Vue.config.errorHandler");
    console.log(err);
    console.log(vm);
    alert("エラーを確認しました");
  } else {
    // 強制的にリロード
    router.go({ path: router.currentRoute.path, force: true });
  }
};
// window.addEventListener("error", (event) => {
//   // console.log("【例外】ERROR:02 -> window.addEventListener:error");
//   // console.log(event);
// });
// window.addEventListener("unhandledrejection", (event) => {
//   // console.log("【例外】ERROR:03 -> window.addEventListener:unhandledrejection");
//   // console.log(event);
// });

// コンポーネント登録 componentフォルダ以下
const files = require.context("./component", true, /\.vue$/);
const components = {};
files.keys().forEach((key) => {
  components[key.replace(/(\.\/|\.vue)/g, "")] = files(key).default;
});

// 読み込んだvueファイルをグローバルコンポーネントとして登録
Object.keys(components).forEach((key) => {
  Vue.component(key, components[key]);
});

// Vue本体
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
