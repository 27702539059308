import Repository from "../repository";

const resource = "/shop_contract_details";

export default {
  admin_list(payload) {
    return Repository.get(`${resource}/admin/list`, { params: payload });
  },
  admin_get(shopContractDetailId) {
    return Repository.get(`${resource}/admin/get/${shopContractDetailId}`);
  },
  admin_create(payload) {
    return Repository.post(`${resource}/admin/create`, payload);
  },
  admin_update(payload) {
    return Repository.put(`${resource}/admin/update`, payload);
  },
  admin_delete(shopContractDetailId) {
    return Repository.delete(
      `${resource}/admin/delete/${shopContractDetailId}`
    );
  },
  admin_all_delete(shopContractId) {
    return Repository.delete(`${resource}/admin/all_delete/${shopContractId}`);
  },
};
