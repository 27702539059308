<template>
  <v-card tile elevation="0" class="item-card pa-2 item-card-backcolor">
    <v-card-text class="pa-0">
      <!-- <div class="pb-2 mb-2">
        <span
          class="text-subtitle-1 font-weight-bold"
          :style="com_data.color.length != 0 ? 'color:' + com_data.color : ''"
          >{{ com_data.name }}</span
        >専用のキャスト情報を設定します。
      </div> -->
      <div class="pt-2">
        <slot name="item_edit"></slot>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ["com_data"],
  setup(props, ctx) {
    return {
      props,
    };
  },
};
</script>
