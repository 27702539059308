import Repository from "../repository";

const resource = "/update_castup_contents";

export default {
  get_shop(shopId) {
    return Repository.get(`${resource}/get_shop/${shopId}`);
  },
  create(payload) {
    return Repository.post(`${resource}/create`, payload);
  },
  update(payload) {
    return Repository.put(`${resource}/update`, payload);
  },
  delete(updateCastupContentId) {
    return Repository.delete(`${resource}/delete/${updateCastupContentId}`);
  },
  save(payload) {
    return Repository.post(`${resource}/save`, payload);
  },
  update_next_check(updateCastupContentId) {
    return Repository.get(
      `${resource}/update_next_check/${updateCastupContentId}`
    );
  },
  get_next_update(updateCastupContentId) {
    return Repository.get(
      `${resource}/get_next_update/${updateCastupContentId}`
    );
  },
};
