import Repository from "../repository";

const resource = "/shop_sites";

export default {
  list(payload) {
    return Repository.get(`${resource}/list`, { params: payload });
  },
  list_all(payload) {
    return Repository.get(`${resource}/list_all`, { params: payload });
  },
  list_getcast(payload) {
    return Repository.get(`${resource}/list_getcast`, { params: payload });
  },
  list_getcast_update(payload) {
    return Repository.get(`${resource}/list_getcast_update`, {
      params: payload,
    });
  },
  list_getschedule(payload) {
    return Repository.get(`${resource}/list_getschedule`, { params: payload });
  },
  list_castup(payload) {
    return Repository.get(`${resource}/list_castup`, { params: payload });
  },
  get(shopSiteId) {
    return Repository.get(`${resource}/get/${shopSiteId}`);
  },
  create(payload) {
    return Repository.post(`${resource}/create`, payload);
  },
  update(payload) {
    return Repository.put(`${resource}/update`, payload);
  },
  delete(shopSiteId) {
    return Repository.delete(`${resource}/delete/${shopSiteId}`);
  },
  admin_get(shopSiteId) {
    return Repository.get(`${resource}/admin/get/${shopSiteId}`);
  },
  admin_list(payload) {
    return Repository.get(`${resource}/admin/list`, { params: payload });
  },
  admin_create(payload) {
    return Repository.post(`${resource}/admin/create`, payload);
  },
  admin_update(payload) {
    return Repository.put(`${resource}/admin/update`, payload);
  },
  admin_delete(shopSiteId) {
    return Repository.delete(`${resource}/admin/delete/${shopSiteId}`);
  },
};
