import Repository from "../repository";

const resource = "/providers";

export default {
  login(payload) {
    return Repository.post(`${resource}/login`, payload);
  },
  get(providerId) {
    return Repository.get(`${resource}/get/${providerId}`);
  },
  create(payload) {
    return Repository.post(`${resource}/create`, payload);
  },
  update(payload) {
    return Repository.put(`${resource}/update`, payload);
  },
};
