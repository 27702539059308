import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

/////// globalなストア ///////
import system from "./modules/system";
import loadingIcon from "./modules/loadingIcon";
import page_meta from "./modules/pageMeta";
import auth from "./modules/auth";
import users from "./modules/users";
import casts from "./modules/casts";
import shops from "./modules/shops";
import shop_sites from "./modules/shopSites";
import templates from "./modules/templates";

import providers from "./modules/providers";

/////// 画面固有のストア ///////
import schedules from "./modules/schedules"; // スケジュール管理
import synchro_cast from "./modules/synchroCast"; // キャスト管理-同期処理

Vue.use(Vuex);

//const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    loadingIcon,
    auth,
    casts,
    page_meta,
    schedules,
    shop_sites,
    shops,
    synchro_cast,
    system,
    users,
    templates,
    providers,
  },
  // strict: debug,
  // plugins: debug ? [createLogger()] : []

  // `createPersistedState()`でインスタンス作成。引数に設定を書く
  plugins: [
    createPersistedState({
      // ストレージのキーを指定。デフォルトではvuex
      key: "casts-sync",

      // 管理対象のステートを指定。pathsを書かない時は`modules`に書いたモジュールに含まれるステート全て。`[]`の時はどれも保存されない
      // paths: ["auth.userId", "auth.userToken", "auth.isLogined"],

      // ストレージの種類を指定する。デフォルトではローカルストレージ
      storage: window.sessionStorage,
    }),
  ],
});
