import Repository from "../repository";

const resource = "/file";

export default {
  upload(payload) {
    return Repository.post(`${resource}/upload`, payload);
  },
  upload_whatsnew(payload) {
    return Repository.post(`${resource}/upload_whatsnew`, payload);
  },
};
