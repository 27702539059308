import Repository from "../repository";

const resource = "/plans";

export default {
  admin_list() {
    return Repository.get(`${resource}/admin/list`);
  },
  admin_get(planId) {
    return Repository.get(`${resource}/admin/get/${planId}`);
  },
  admin_create(payload) {
    return Repository.post(`${resource}/admin/create`, payload);
  },
  admin_update(payload) {
    return Repository.put(`${resource}/admin/update`, payload);
  },
  admin_delete(planId) {
    return Repository.delete(`${resource}/admin/delete/${planId}`);
  },
};
