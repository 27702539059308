import setting from "./setting.js";
import { repositoryFactory } from "@/repository/repositoryFactory";

const ShopRepository = repositoryFactory.get("shops");
const shopSitesRepository = repositoryFactory.get("shopSites");

// 共通で使用する日付関連の定義
const utilFunc = {
  getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
  },
  getKeyByItemText(object, value) {
    return Object.keys(object).find((key) => object[key].text === value);
  },
  getKeyByItemLabel(object, value) {
    return Object.keys(object).find((key) => object[key].label === value);
  },
  // 配列を比較(長さ、内容、順番、再起処理なし) 一致していればtrue
  compareArray(before, after) {
    // console.log(before.length, after.length);
    if (before.length != after.length) return false;
    for (let index = 0; index < before.length; index++) {
      // console.log("before_" + index, JSON.stringify(before[index]));
      // console.log("after_" + index, JSON.stringify(after[index]));
      if (JSON.stringify(before[index]) != JSON.stringify(after[index])) {
        return false;
      }
    }
    return true;
  },
  // オブジェクトを比較 一致していればtrue
  compareObject(before, after) {
    const beforeJSON = JSON.stringify(this.objectSort(before));
    const afterJSON = JSON.stringify(this.objectSort(after));
    return beforeJSON === afterJSON;
  },
  objectSort(obj) {
    // ソートする
    const sorted = Object.entries(obj ?? "").sort();
    // valueを調べ、objectならsorted entriesに変換する
    for (let i in sorted) {
      const val = sorted[i][1];
      if (typeof val === "object") {
        sorted[i][1] = this.objectSort(val);
      }
    }
    return sorted;
  },
  // 今日から指定日後の日付を取得する
  getDayLater(n) {
    const date = new Date();
    date.setDate(date.getDate() + n);
    return date;
  },
  // 今日から1ヶ月後の日付を取得する
  getMonthLater(n) {
    const date = new Date();
    date.setMonth(date.getMonth() + n);
    return date;
  },
  // site_contentテーブルの設定確認(勤務ステータス更新で使用)
  checkSiteContentConfig(site_conetnt_id, config) {
    if (config == null) {
      if (
        !setting.working_status_required_config_ids.includes(site_conetnt_id)
      ) {
        return true;
      }

      return false;
    }
    const checkItem = setting.working_status_config.find(
      (item) => item.site_content_id == site_conetnt_id
    ).items;

    // checkItemをループして、requiredの項目がconfigに設定されているかチェック
    for (const key in checkItem) {
      const value = checkItem[key];
      if (value.rules.includes("required")) {
        if (key in config) {
          if (config[key] == null || config[key] == "") {
            return false;
          }
        } else {
          return false;
        }
      }
    }

    return true;
  },
  async checkShop(shop_id, router) {
    // 切り替え先の店舗が有効かチェック
    const checkData = await ShopRepository.get_with_prop(shop_id)
      .then((response) => {
        if (response.data) {
          return response.data;
        }
      })
      .catch((error) => {
        if (error.response.status == 404) {
          return false;
        } else {
          throw (
            "ERROR:utilFunc.js/checkShop ShopRepository.get (" + error + ")"
          );
        }
      });

    if (checkData === false) {
      // 店舗の切り替えに問題があった場合、ログアウトする
      alert(
        "操作中の店舗で問題がありました。ログアウトします。\n※契約期間が終了している可能性があります。"
      );
      router.push({ name: "login" });
    }
    return checkData;
  },
  async checkShopWithOption(shop_id, router) {
    // 切り替え先の店舗が有効かチェック
    const checkData = await ShopRepository.get_with_prop_valid_option(shop_id)
      .then((response) => {
        if (response.data) {
          return response.data;
        }
      })
      .catch((error) => {
        if (error.response.status == 404) {
          return false;
        } else {
          throw (
            "ERROR:utilFunc.js/checkShop ShopRepository.get (" + error + ")"
          );
        }
      });

    if (checkData === false) {
      // 店舗の切り替えに問題があった場合、ログアウトする
      alert(
        "操作中の店舗で問題がありました。ログアウトします。\n※契約期間が終了している可能性があります。"
      );
      router.push({ name: "login" });
    }
    return checkData;
  },
  // 指定されたsite_idの媒体が登録済みかチェック(削除済みも含める)
  async checkSiteId(shop_id, site_id) {
    const params = new URLSearchParams();
    params.append("shop_id", shop_id);
    const shopSiteData = await shopSitesRepository
      .list_all(params)
      .then((response) => {
        if (response.data) {
          return response.data;
        }
      })
      .catch((error) => {
        if (error.response.status == 404) {
          return false;
        } else {
          throw (
            "ERROR:utilFunc.js/checkSiteId ShopRepository.get (" + error + ")"
          );
        }
      });

    if (shopSiteData === false) {
      return false;
    }

    // 取得したshopSiteDataからsite_idが一致してなければTrueを返す
    return !shopSiteData.find((item) => item.site_id == site_id);
  },
  // valid_optionの配列から指定された契約の件数を返す
  async getValidOptionCount(valid_option, code) {
    for (const value of valid_option) {
      const match = value.match(/^(.*?)\[(\d+)\]$/);
      if (match && match[1] == code) {
        return match[2];
      }
    }
    return 0;
  },
};

export default utilFunc;
