import Repository from "../repository";

const resource = "/site_contents";

export default {
  get(templateId) {
    return Repository.get(`${resource}/get/${templateId}`);
  },
  list_site(payload) {
    return Repository.get(`${resource}/list_site`, { params: payload });
  },
  list_shop(payload) {
    return Repository.get(`${resource}/list_shop`, { params: payload });
  },
};
