import Repository from "../repository";

const resource = "/shop_props";

export default {
  save_all(payload) {
    return Repository.post(`${resource}/save_all`, { shop_prop: payload });
  },
  // get(castPropId) {
  //   return Repository.get(`${resource}/get/${castPropId}`);
  // },
  // get_all(payload) {
  //   return Repository.post(`${resource}/get_all`, payload);
  // },
  create(payload) {
    return Repository.post(`${resource}/create`, payload);
  },
  update(payload) {
    return Repository.put(`${resource}/update`, payload);
  },
  // delete(castPropId) {
  //   return Repository.delete(`${resource}/delete/${castPropId}`);
  // },
};
