const state = {
  id: 0,
  loginid: "",
  name: "",
  email: "",
};

const getters = {
  allState: (state) => state,
};

const actions = {
  setUser({ commit }, value) {
    commit("setUser", value);
  },
  changeUserName({ commit }, value) {
    commit("setUserName", value);
  },
  unsetUser({ commit }) {
    commit("unsetUser");
  },
};

const mutations = {
  setUser(state, payload) {
    state.id = payload.id;
    state.loginid = payload.loginid;
    state.name = payload.name;
    state.email = payload.email;
  },
  setUserName(state, value) {
    state.name = value;
  },
  unsetUser(state) {
    state.id = 0;
    state.loginid = "";
    state.name = "";
    state.email = "";
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
