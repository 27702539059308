const state = {
  loading: false,
};

const getters = {
  // ローディングの状態を返す
  isLoading: (state) => state,
};

const actions = {
  showIcon({ commit }) {
    commit("showLoadingIcon");
  },
  hideIcon({ commit }) {
    commit("hideLoadingIcon");
  },
};

const mutations = {
  showLoadingIcon(state) {
    state.loading = true;
  },
  hideLoadingIcon(state) {
    state.loading = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
