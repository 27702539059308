import Repository from "../repository";

const resource = "/update_contents";

export default {
  get(updateContentsId) {
    return Repository.get(`${resource}/get/${updateContentsId}`);
  },
  get_all(updateContentsId) {
    return Repository.get(`${resource}/get_all/${updateContentsId}`);
  },
  list(shopId) {
    return Repository.get(`${resource}/list/${shopId}`);
  },
  list_working(shopId) {
    return Repository.get(`${resource}/list_working/${shopId}`);
  },
  create(payload) {
    return Repository.post(`${resource}/create`, payload);
  },
  update(payload) {
    return Repository.put(`${resource}/update`, payload);
  },
  working_update_next(payload) {
    return Repository.put(`${resource}/working_update_next`, payload);
  },
  update_next(payload) {
    return Repository.put(`${resource}/update_next`, payload);
  },
  update_next_check(updateContentsId) {
    return Repository.get(`${resource}/update_next_check/${updateContentsId}`);
  },
  delete(updateContentsId) {
    return Repository.delete(`${resource}/delete/${updateContentsId}`);
  },
  // save(payload) {
  //   return Repository.post(`${resource}/save`, payload);
  // },
  save_config(payload) {
    return Repository.post(`${resource}/save_config`, payload);
  },
  save_esutama_schedule(payload) {
    return Repository.post(`${resource}/save_esutama_schedule`, payload);
  },
  admin_save_esutama_schedule(payload) {
    return Repository.post(`${resource}/admin/save_esutama_schedule`, payload);
  },
  admin_update_content_status() {
    return Repository.get(`${resource}/admin/update_content_status`);
  },
  admin_update_content_status_past() {
    return Repository.get(`${resource}/admin/update_content_status_past`);
  },
};
