import Repository from "../repository";

const resource = "/shop_site_whats_news";

export default {
  get(shopSiteWhatsNewId) {
    return Repository.get(`${resource}/get/${shopSiteWhatsNewId}`);
  },
  list_shopsite(shopSiteId) {
    return Repository.get(`${resource}/list_shopsite/${shopSiteId}`);
  },
  copy(payload) {
    return Repository.post(`${resource}/copy`, payload);
  },
  create(payload) {
    return Repository.post(`${resource}/create`, payload);
  },
  update(payload) {
    return Repository.put(`${resource}/update`, payload);
  },
  delete(shopSiteWhatsNewId) {
    return Repository.delete(`${resource}/delete/${shopSiteWhatsNewId}`);
  },
};
