import { set } from "@vue/composition-api";

const state = {
  templateList: [],
  contentFormatList: [],
};

const getters = {
  getTemplateList: (state) => state.templateList,
  getContentFormatList: (state) => state.contentFormatList,
};

const actions = {
  // ストアにリストをセットする
  setTemplateList({ commit }, value) {
    commit("setTemplateList", value);
  },
  // ストアに１レコード追加する
  addTemplate({ commit }, value) {
    commit("addTemplate", value);
  },
  // ストアのレコードを更新する
  updateTemplate({ commit }, value) {
    commit("updateTemplate", value);
  },
  // ストアのレコードを削除する
  deleteTemplate({ commit }, template_id) {
    commit("deleteTemplate", template_id);
  },
  // ストアにリストをセットする
  setContentFormatList({ commit }, value) {
    commit("setContentFormatList", value);
  },
  // ストアをクリアする
  clear({ commit }) {
    commit("clear");
  },
};

const mutations = {
  setTemplateList(state, array) {
    Object.keys(array).forEach(function (key) {
      state.templateList.push({
        id: array[key].id,
        content_format_id: array[key].content_format_id,
        content_format_name: array[key].content_format_name,
        image: array[key].image,
        name: array[key].name,
      });
    });
  },
  addTemplate(state, value) {
    state.templateList.push({
      id: value.id,
      content_format_id: value.content_format_id,
      content_format_name: value.content_format_name,
      name: value.name,
      image: value.image,
    });
  },
  updateTemplate(state, value) {
    state.templateList.forEach(function (template, index) {
      if (value.id == template.id) {
        set(template, "id", value.id);
        set(template, "content_format_id", value.content_format_id);
        set(template, "content_format_name", value.content_format_name);
        set(template, "name", value.name);
        set(template, "image", value.image);
      }
    });
  },
  deleteTemplate(state, template_id) {
    state.templateList.forEach(function (template, index) {
      if (template_id == template.id) {
        state.templateList.splice(index, 1);
      }
    });
  },
  setContentFormatList(state, array) {
    Object.keys(array).forEach(function (key) {
      state.contentFormatList.push({
        id: array[key].id,
        name: array[key].name,
        code: array[key].code,
        value: array[key].id,
        text: array[key].name,
      });
    });
  },
  clear(state) {
    state.templateList.splice(0);
    state.contentFormatList.splice(0);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
