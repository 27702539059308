const state = {
  userId: 0,
  userToken: "",
  isLogined: false,
  type: "",
};

const getters = {
  // ログイン状態を返す
  isLogined: (state) => state.isLogined,
  getUserId: (state) => state.userId,
  getType: (state) => state.type,
  allState: (state) => state,
  // トークンをヘッダー用のオブジェクトで返す
  addHeader: (state) => {
    if (state.isLogined) {
      return "Bearer " + state.userToken;
    } else {
      return "";
    }
  },
};

const actions = {
  loginUserAuth({ commit }, value) {
    commit("setloginUser", value);
  },
  logoutUser({ commit }) {
    commit("unsetloginUser");
  },
};

const mutations = {
  setloginUser(state, payload) {
    state.userId = payload.user_id;
    state.userToken = payload.access_token;
    state.isLogined = true;
    state.type = payload.type;
  },
  unsetloginUser(state) {
    state.userId = 0;
    state.userToken = "";
    state.isLogined = false;
    state.type = "";
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
