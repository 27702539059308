import Repository from "../repository";

const resource = "/synchro";

export default {
  login_check(payload) {
    return Repository.post(`${resource}/login_check`, payload);
  },
  sync_cast_simple(payload) {
    return Repository.post(`${resource}/sync_cast_simple`, payload);
  },
  sync_update_cast(payload) {
    return Repository.post(`${resource}/sync_update_cast`, payload);
  },
  sync_cast(payload) {
    return Repository.post(`${resource}/sync_cast`, payload);
  },
  sync_schedule(payload) {
    return Repository.post(`${resource}/sync_schedule`, payload);
  },
  get_cast_order(payload) {
    return Repository.post(`${resource}/get_cast_order`, payload);
  },
  sync_cast_order(payload) {
    return Repository.post(`${resource}/sync_cast_order`, payload);
  },
  get_schedule(payload) {
    return Repository.post(`${resource}/get_schedule`, payload);
  },
  sync_working(payload) {
    return Repository.post(`${resource}/sync_working`, payload);
  },
  admin_login_check(payload) {
    return Repository.post(`${resource}/admin/login_check`, payload);
  },
};
