import Repository from "../repository";

const resource = "/shop_site_contents";

export default {
  list_shopsite(payload) {
    return Repository.get(`${resource}/list_shopsite`, { params: payload });
  },
  get(shopSiteId) {
    return Repository.get(`${resource}/get/${shopSiteId}`);
  },
  create(payload) {
    return Repository.post(`${resource}/create`, payload);
  },
  update(payload) {
    return Repository.put(`${resource}/update`, payload);
  },
  delete(shopSiteId) {
    return Repository.delete(`${resource}/delete/${shopSiteId}`);
  },
  save_list(payload) {
    return Repository.post(`${resource}/save_list`, payload);
  },
  admin_list_shopsite(payload) {
    return Repository.get(`${resource}/admin/list_shopsite`, {
      params: payload,
    });
  },
  admin_save_list(payload) {
    return Repository.post(`${resource}/admin/save_list`, payload);
  },
};
