// バックエンドのヤトイテのバージョン番号を保持

const state = {
  version: "",
};

const getters = {
  getVersion: (state) => state.version,
};

const actions = {
  setVersion({ commit }, value) {
    commit("setVersion", value);
  },
};

const mutations = {
  setVersion(state, str) {
    state.version = str;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
