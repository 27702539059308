import Repository from "../repository";

const resource = "/shop_site_pickups";

export default {
  list_shopsite(shopSiteId) {
    return Repository.get(`${resource}/list_shopsite/${shopSiteId}`);
  },
  save_list(payload) {
    return Repository.post(`${resource}/save_list`, payload);
  },
};
