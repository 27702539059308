import Repository from "../repository";

const resource = "/result_logs";

export default {
  // get(resultLogId) {
  //   return Repository.get(`${resource}/get/${resultLogId}`);
  // },
  list(payload) {
    return Repository.post(`${resource}/list`, payload);
  },
  // create(payload) {
  //   return Repository.post(`${resource}/create`, payload);
  // },
  // update(payload) {
  //   return Repository.put(`${resource}/update`, payload);
  // },
  // delete(resultLogId) {
  //   return Repository.delete(`${resource}/delete/${resultLogId}`);
  // },
  admin_list_date(payload) {
    return Repository.post(`${resource}/admin/list_date`, payload);
  },
  admin_list_hour(payload) {
    return Repository.post(`${resource}/admin/list_hour/`, payload);
  },
  admin_list_date_home() {
    return Repository.post(`${resource}/admin/list_date_home`);
  },
  admin_task_server_count() {
    return Repository.get(`${resource}/admin/task_server_count`);
  },
};
