import setting from "@/common/setting.js";
import { set } from "@vue/composition-api";

const state = {
  currentShopId: 0,
  currentShopIndex: 0,
  shopList: {},
  shopCount: 0,
};

const getters = {
  // ユーザーが利用できる店舗リストを返す
  allList: (state) => state.shopList,
  // 現在選択中の店舗を返す
  currentShop: (state) => {
    return {
      id: state.currentShopId,
      index: state.currentShopIndex,
      name: state.shopList[state.currentShopIndex].name,
      data: state.shopList[state.currentShopIndex],
    };
  },
  // 現在選択中の店舗を返す
  countShop: (state) => state.shopCount,
  dateline: (state) => {
    const currentShop = state.shopList[state.currentShopIndex];
    let result = setting.sys_dateline;
    if (currentShop.prop != null) {
      const checkKey = currentShop.prop.find((list) => list.key == "dateline");
      if (typeof checkKey !== "undefined") {
        result = checkKey.value;
      }
    }
    return result;
  },
};

const actions = {
  // ユーザーが利用できる店舗をリストに格納する
  setShopList({ commit }, value) {
    commit("countList", value);
    if (value.length) {
      commit("setShopList", value);
      if (state.currentShopId === 0) {
        commit("setCurrentShop", value[0]);
      } else {
        // リストに現在選択中の店舗があるか？
        const result = value.some((u) => u.id === state.currentShopId);
        if (result) {
          // ある：何もしない
        } else {
          // ない：最初の店舗を設定する
          commit("setCurrentShop", value[0]);
        }
      }
    }
  },
  // 選択中の店舗を設定
  setCurrentShop({ commit }, value) {
    commit("setCurrentShop", value);
  },
  // 選択中の店舗を削除
  unsetShop({ commit }) {
    commit("unsetShop");
  },
  // 現在の店舗に店舗項目を保存する
  setProp({ commit }, value) {
    commit("setProp", value);
  },
  // 選択中の店舗を更新
  updateCurrentShop({ commit }, value) {
    commit("updateCurrentShop", value);
  },
};

const mutations = {
  setShopList(state, payload) {
    state.shopList = payload;
  },
  countList(state, payload) {
    state.shopCount = payload.length;
  },
  setCurrentShop(state, payload) {
    state.currentShopId = payload.id;
    state.currentShopIndex = state.shopList.findIndex(
      (list) => list.id == payload.id
    );
  },
  unsetShop(state) {
    state.currentShopId = 0;
    state.currentShopIndex = 0;
    state.shopList = {};
    state.shopCount = 0;
  },
  setProp(state, payload) {
    let prop = [];
    let index = -1;
    if (state.shopList[state.currentShopIndex].prop == null) {
      state.shopList[state.currentShopIndex].prop = [];
    }
    prop = state.shopList[state.currentShopIndex].prop;
    index = prop.findIndex((list) => list.key == payload.key);

    if (index != -1) {
      // store更新
      prop[index].value = payload.value;
    } else {
      // store新規追加
      const newProp = {
        id: payload.id ?? 0,
        shop_id: state.currentShopId,
        key: payload.key,
        value: payload.value,
      };
      prop.push(newProp);
    }
  },
  updateCurrentShop(state, payload) {
    Object.keys(payload).forEach(function (key) {
      if (key == "prop") {
        Object.keys(payload[key]).forEach(function (pkey) {
          set(
            state.shopList[state.currentShopIndex].prop,
            pkey,
            payload[key][pkey]
          );
        });
      } else {
        set(state.shopList[state.currentShopIndex], key, payload[key]);
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
