<template>
  <v-app>
    <vertical-nav-menu :is-drawer-open.sync="isDrawerOpen"></vertical-nav-menu>
    <v-app-bar app dense flat color="white" style="z-index: 5">
      <v-app-bar-nav-icon
        class="d-lg-none"
        @click="isDrawerOpen = !isDrawerOpen"
      ></v-app-bar-nav-icon>
      <v-toolbar-title class="pl-0">{{ page_title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu left bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item>
            <v-list-item-title>{{ name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main>
      <v-card elevation="0" :loading.sync="loading" :disabled.sync="loading">
        <div class="">
          <!-- <header-title>{{ page_title }}</header-title> -->
          <router-view></router-view>
        </div>
      </v-card>
    </v-main>
  </v-app>
</template>

<script>
import { useRouter } from "@/utils";
import { ref, watch } from "@vue/composition-api";
import store from "@/store/index.js";

import verticalNavMenu from "./verticalNavMenu.vue";

export default {
  components: {
    verticalNavMenu,
  },
  setup(_, ctx) {
    const isDrawerOpen = ref(null);
    let page_title = ref(store.getters["page_meta/allState"].title);
    let name = ref(store.getters["users/allState"].name + "様");
    let loading = ref(store.getters["loadingIcon/isLoading"].loading);

    // ログイン者表示
    watch(store.getters["users/allState"], (state) => {
      name.value = state.name + "様";
    });

    // ローディングアイコン表示
    watch(store.getters["loadingIcon/isLoading"], (state) => {
      loading.value = state.loading;
    });

    // ページタイトル表示
    watch(store.getters["page_meta/allState"], (state) => {
      page_title.value = state.title;
    });

    return {
      isDrawerOpen,
      name,
      loading,
      page_title,
    };
  },
};
</script>
