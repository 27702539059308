<template>
  <div>
    <v-btn class="info font-weight-bold" @click="showDialog"
      ><v-icon left>mdi-plus-box</v-icon
      ><span class="d-none d-sm-flex">新規登録</span
      ><span class="d-sm-none">新規</span></v-btn
    >
    <v-dialog
      v-model="dialog"
      scrollable
      persistent
      width="100%"
      max-width="1200px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">契約設定</span>
        </v-card-title>
        <v-card-text class="scrollable-content">
          <input type="hidden" v-model="item.id" />
          <ValidationObserver ref="observer">
            <h3 id="title_head">契約期間</h3>
            <div class="mt-2 mb-2">
              契約期間を設定して下さい。締め日以前の日付は修正できません
            </div>
            <v-container fluid>
              <v-row>
                <v-col cols="12" sm="3">
                  <ValidationProvider
                    v-slot="{ errors, valid }"
                    name="開始日"
                    rules="required"
                  >
                    <v-text-field
                      ref="start_date"
                      v-model="item.start_date"
                      name="start_date"
                      :error-messages="errors"
                      :success="valid"
                      type="date"
                      label="開始日"
                      hint="契約の開始日を設定して下さい[必須項目]"
                      persistent-hint
                      outlined
                      :min="closeDatePlusOne"
                      :disabled="item.id != 0 && item.start_date <= closeDate"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" sm="3">
                  <ValidationProvider
                    v-slot="{ errors, valid }"
                    name="終了日"
                    :rules="`${item.auto_flg ? '' : 'required'}`"
                  >
                    <v-text-field
                      ref="end_date"
                      v-model="item.end_date"
                      name="end_date"
                      :error-messages="errors"
                      :success="valid"
                      type="date"
                      label="終了日"
                      hint="契約の終了日を設定して下さい[必須項目]"
                      persistent-hint
                      outlined
                      :min="item.id == 0 ? closeDatePlusOne : closeDate"
                      :disabled="item.auto_flg"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-switch
                    v-model="item.auto_flg"
                    label="自動更新"
                    @change="onChangeAutoFlg"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-container>
            <h3 id="title_head">明細情報</h3>
            <div class="mt-2 mb-2">
              期間中に契約するプランをすべて設定して下さい。「初期費用」は契約開始月に1度だけ請求が発生します。締め日以前の期間を持つ契約は修正できません
            </div>
            <v-btn
              class="mb-4"
              color="success"
              @click="showDetailDialog"
              :disabled="item.start_date <= closeDate"
            >
              <v-icon left>mdi-plus-box</v-icon>明細追加
            </v-btn>
            <v-data-table
              id="shop_contract_detail"
              :headers="headers"
              :items="item.shopContractDetailList"
              item-key="random_id"
              :mobile-breakpoint="0"
              :items-per-page="-1"
              :footer-props="{
                'items-per-page-options': [-1],
                'items-per-page-text': '表示件数',
              }"
              hide-default-footer
            >
              <!-- eslint-disable-next-line -->
              <template v-slot:footer.page-text="props">
                {{ props.pageStart }} ～{{ props.pageStop }}件／全{{
                  props.itemsLength
                }}件
              </template>
              <!-- eslint-disable-next-line -->
              <template v-slot:item.edit="{ item }">
                <v-icon
                  class="pa-1"
                  @click="editDetailItem(item)"
                  :disabled="item.edit_disabled"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <!-- eslint-disable-next-line -->
              <template v-slot:item.initial_fee="{ item }">
                <div class="right-input">
                  {{ item.initial_fee.toLocaleString() }}
                </div>
              </template>
              <!-- eslint-disable-next-line -->
              <template v-slot:item.fixed_fee="{ item }">
                <div class="right-input">
                  {{ item.fixed_fee.toLocaleString() }}
                </div>
              </template>
              <!-- eslint-disable-next-line -->
              <template v-slot:item.quantity="{ item }">
                <div class="right-input">
                  {{ item.quantity.toLocaleString() }}
                </div>
              </template>
              <!-- eslint-disable-next-line -->
              <template v-slot:item.total_fixed_fee="{ item }">
                <div class="right-input">
                  {{ item.total_fixed_fee.toLocaleString() }}
                </div>
              </template>
              <!-- eslint-disable-next-line -->
              <template v-slot:item.delete="{ item }">
                <v-icon
                  class="pa-1"
                  @click="deleteDetailItem(item)"
                  :disabled="item.edit_disabled"
                >
                  mdi-delete
                </v-icon>
              </template>
              <template v-slot:no-data> 表示するデータがありません </template>
            </v-data-table>

            <h3 id="title_head">契約期間中の月額料金【税別】</h3>
            <div class="mt-2 mb-2"></div>
            <v-container fluid>
              <v-row>
                <v-col cols="12" sm="3">
                  <p class="text-right mb-0">
                    <span class="text-h4">
                      {{ item.total_fee.toLocaleString() }}
                    </span>
                    円
                  </p>
                </v-col>
              </v-row>
            </v-container>

            <h3 id="title_head">コメント・補足</h3>
            <v-container>
              <ValidationProvider
                v-slot="{ errors, valid }"
                name="コメント・補足"
                rules="max:10000"
              >
                <v-textarea
                  v-model="item.description"
                  name="description"
                  :error-messages="errors"
                  :success="valid"
                  hint="必要に応じて、自由にコメントを入力して下さい。"
                  persistent-hint
                  outlined
                  rows="3"
                />
              </ValidationProvider>
            </v-container>
          </ValidationObserver>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row justify="end">
              <v-col cols="6" sm="3">
                <general-button
                  btn_color="gray"
                  btn_block
                  @click-event="cancelDialog"
                  ><template v-slot:icon
                    ><v-icon left>mdi-close-circle</v-icon></template
                  >キャンセル</general-button
                >
              </v-col>
              <v-col cols="6" sm="3">
                <general-button
                  btn_type="info"
                  btn_block
                  @click-event="onSubmit"
                  ><template v-slot:icon
                    ><v-icon left>mdi-content-save</v-icon></template
                  >保存</general-button
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="detailDialog"
      scrollable
      persistent
      width="100%"
      max-width="1000px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">追加するプラン</span>
        </v-card-title>
        <v-card-text class="scrollable-content">
          <input type="hidden" v-model="detailItem.id" />
          <ValidationObserver ref="detailObserver">
            <h3 id="title_head">プラン選択</h3>
            <div class="mt-2 mb-2">契約に追加するプランを設定して下さい。</div>
            <v-container fluid>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-select
                    :items="planSelectList"
                    v-model="detailItem.plan_code"
                    label="プラン"
                    hint="プランを選択してください"
                    persistent-hint
                    outlined
                    :success="detailItem.plan_code == '' ? false : true"
                    @change="onChangePlan"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
            <div v-show="detailItem.plan_code">
              <h3 id="title_head">料金設定【税別】</h3>
              <div class="mt-2 mb-2">
                初期費用と月額利用料には定価を初期表示しています。
              </div>
              <v-container fluid>
                <v-row>
                  <v-col cols="6" sm="3">
                    <ValidationProvider
                      v-slot="{ errors, valid }"
                      name="初期費用"
                      rules="required|max:100000"
                    >
                      <v-text-field
                        v-model="detailItem.initial_fee"
                        name="initial_fee"
                        :error-messages="errors"
                        :success="valid"
                        label="初期費用"
                        hint="初期費用を入力して下さい。"
                        persistent-hint
                        outlined
                        type="number"
                        min="0"
                        prefix="¥"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" sm="3">
                    <ValidationProvider
                      v-slot="{ errors, valid }"
                      name="月額利用料"
                      rules="required|max:100000"
                    >
                      <v-text-field
                        v-model="detailItem.fixed_fee"
                        name="fixed_fee"
                        :error-messages="errors"
                        :success="valid"
                        label="月額利用料"
                        hint="月額利用料を入力して下さい。"
                        persistent-hint
                        outlined
                        type="number"
                        min="0"
                        prefix="¥"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="4" sm="2">
                    <ValidationProvider
                      v-slot="{ errors, valid }"
                      name="個数"
                      rules="required|min:1|max:100"
                    >
                      <v-text-field
                        v-model="detailItem.quantity"
                        name="quantity"
                        :error-messages="errors"
                        :success="valid"
                        label="個数"
                        hint="個数を入力して下さい。"
                        persistent-hint
                        outlined
                        type="number"
                        min="1"
                        suffix="個"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </ValidationObserver>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row justify="end">
              <v-col cols="6" sm="3">
                <general-button
                  btn_color="gray"
                  btn_block
                  @click-event="cancelDetailDialog"
                  ><template v-slot:icon
                    ><v-icon left>mdi-close-circle</v-icon></template
                  >キャンセル</general-button
                >
              </v-col>
              <v-col cols="6" sm="3">
                <general-button
                  btn_type="info"
                  btn_block
                  :btn_disabled="detailItem.plan_code == ''"
                  @click-event="onSubmitDetail"
                  ><template v-slot:icon
                    ><v-icon left>mdi-content-save</v-icon></template
                  >保存</general-button
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  set,
  reactive,
  toRefs,
  defineComponent,
  watch,
} from "@vue/composition-api";
import { repositoryFactory } from "@/repository/repositoryFactory";
import store from "@/store/index.js";
import setting from "@/common/setting.js";
import utilDate from "@/common/utilDate.js";

export default defineComponent({
  props: ["shop"],
  setup(props, ctx) {
    const shopsRepository = repositoryFactory.get("shops");
    const shopContractsRepository = repositoryFactory.get("shopContracts");
    const shopContractDetailsRepository = repositoryFactory.get(
      "shopContractDetails"
    );
    const plansRepository = repositoryFactory.get("plans");
    const systemPropsRepository = repositoryFactory.get("systemProps");
    const unclaimedContractsRepository =
      repositoryFactory.get("unclaimedContracts");

    const state = reactive({
      closeDate: "",
      closeDatePlusOne: "",
      dialog: false, // ダイアログ表示用
      detailDialog: false, // 明細ダイアログ表示用
      planList: [],
      planSelectList: [],
      today: utilDate.getDateFormatLong(new Date()),
      prevItem: {}, // 編集前のデータを保持しておく
      item: {
        id: 0,
        user_id: 0,
        shop_id: 0,
        start_date: null,
        end_date: null,
        description: "",
        auto_flg: false,
        shopContractDetailList: [],
        total_fee: 0,
      },
      detailItem: {
        random_id: 0,
        id: 0,
        shop_contract_id: 0,
        plan_code: "",
        initial_fee: null,
        fixed_fee: null,
        quantity: null,
        sort: 9999,
        plan_name: "",
        plan_type_name: "",
      },
      headers: [
        {
          text: "編集",
          value: "edit",
          sortable: false,
          align: "center",
          width: 60,
        },
        {
          text: "プラン",
          value: "plan_type_name",
          sortable: false,
          class: "td_plan_type_name",
        },
        {
          text: "名前",
          value: "plan_name",
          sortable: false,
          class: "td_plan_name",
        },
        {
          text: "初期費用",
          value: "initial_fee",
          sortable: true,
          class: "td_initial_fee",
        },
        {
          text: "月額利用料",
          value: "fixed_fee",
          sortable: true,
          class: "td_fixed_fee",
        },
        {
          text: "個数",
          value: "quantity",
          sortable: true,
          class: "td_quantity",
        },
        {
          text: "月額合計",
          value: "total_fixed_fee",
          sortable: true,
          class: "td_total_fixed_fee",
        },
        {
          text: "削除",
          value: "delete",
          sortable: false,
          align: "center",
          width: 60,
        },
      ],
    });

    // 新規作成
    const showDialog = async () => {
      await store.dispatch("loadingIcon/showIcon"); // ローディング表示

      // 初期値を設定
      state.item.id = 0;
      state.item.user_id = props.shop.user_id;
      state.item.shop_id = props.shop.id;
      state.item.start_date = state.today;
      state.item.auto_flg = true;
      state.prevItem = state.item;

      // ダイアログ開く
      state.dialog = true;

      // ローディング非表示
      await store.dispatch("loadingIcon/hideIcon");
    };

    // 新規作成
    const showDetailDialog = async () => {
      await store.dispatch("loadingIcon/showIcon"); // ローディング表示

      // ダイアログ開く
      state.detailDialog = true;

      // ローディング非表示
      await store.dispatch("loadingIcon/hideIcon");
    };

    const clearDialog = async () => {
      // 各種項目初期化
      state.item.id = 0;
      state.item.user_id = 0;
      state.item.shop_id = 0;
      state.item.start_date = null;
      state.item.end_date = null;
      state.item.description = "";
      state.item.auto_flg = false;
      state.item.shopContractDetailList.splice(0);
      state.total_fee = 0;
      state.prevItem = state.item;
    };

    const clearDetailDialog = async () => {
      // 各種項目初期化
      state.detailItem.random_id = new Date().getTime();
      state.detailItem.id = 0;
      state.detailItem.shop_contract_id = 0;
      state.detailItem.plan_code = "";
      state.detailItem.initial_fee = null;
      state.detailItem.fixed_fee = null;
      state.detailItem.quantity = null;
      state.detailItem.sort = 9999;
      state.detailItem.plan_name = "";
    };

    const editDialog = async (shopContractId) => {
      // console.log("closeDate", state.closeDate);
      // console.log("closeDatePlusOne", state.closeDatePlusOne);
      await shopContractsRepository
        .admin_get_with_details(shopContractId)
        .then((response) => {
          if (response.data) {
            // データ追加OK
            state.item.id = response.data.id;
            state.item.user_id = response.data.user_id;
            state.item.shop_id = response.data.shop_id;
            state.item.start_date = response.data.start_date;
            if (response.data.end_date == setting.auto_flag_end_date) {
              state.item.end_date = null;
              state.item.auto_flg = true;
            } else {
              state.item.end_date = response.data.end_date;
              state.item.auto_flg = false;
            }
            state.item.description = response.data.description;
            state.item.shopContractDetailList.splice(0);
            let tmp_total_fee = 0;
            if ("detail" in response.data) {
              if (response.data.detail != null) {
                Object.keys(response.data.detail).forEach(function (key) {
                  const detail = response.data.detail[key];
                  const total_fixed_fee =
                    (detail.fixed_fee == null
                      ? state.planList.find((x) => x.code === detail.plan_code)
                          .default_fixed_fee
                      : detail.fixed_fee) * detail.quantity;
                  const shop_contract_detail = {
                    random_id: detail.id,
                    id: detail.id,
                    shop_contract_id: detail.shop_contract_id,
                    plan_code: detail.plan_code,
                    initial_fee: detail.initial_fee,
                    fixed_fee: detail.fixed_fee,
                    quantity: detail.quantity,
                    total_fixed_fee: total_fixed_fee,
                    sort: detail.sort,
                    plan_type_name: state.planList.find(
                      (x) => x.code === detail.plan_code
                    ).type_label,
                    plan_name: state.planList.find(
                      (x) => x.code === detail.plan_code
                    ).name,
                    edit_disabled: state.item.start_date <= state.closeDate,
                  };
                  state.item.shopContractDetailList.push(shop_contract_detail);
                  tmp_total_fee += total_fixed_fee;
                });
              }
            }
            state.item.total_fee = tmp_total_fee;
            state.prevItem = state.item;
          }
        })
        .catch((error) => {
          throw (
            "ERROR:contractDialogComponent.vue/editDialog shopContractsRepository.admin_get_with_details (" +
            error +
            ")"
          );
        });
      state.dialog = true;
    };

    // 保存処理
    const onSubmit = async () => {
      // 入力チェック エラー状態の部品が無いか調べる
      const isValid = await ctx.refs.observer.validate();
      if (!isValid) {
        // エラー処理
        console.log("エラー", ctx.refs.observer);
        return;
      }

      // 論理チェック
      const new_flg = state.item.id == 0;

      // 新規で開始日が締め日以前の場合はエラー(開始日の入力幅をminで制御するようにしたので無し)
      // if (state.item.start_date <= state.closeDate) {
      //   alert("開始日は締め日以降を設定して下さい。");
      //   ctx.refs.start_date.focus();
      //   return;
      // }
      // 開始日が終了日より後の場合はエラー
      if (state.item.end_date != null) {
        if (state.item.start_date > state.item.end_date) {
          alert("開始日は終了日より前の日付を設定して下さい。");
          ctx.refs.start_date.focus();
          return;
        }
      }
      // // 終了日が締め日以前の場合はエラー(開始日の入力幅をminで制御するようにしたので無し)
      // if (
      //   state.item.end_date != null &&
      //   state.item.end_date < state.closeDate
      // ) {
      //   alert("終了日は締め日以降を設定して下さい。");
      //   ctx.refs.end_date.focus();
      //   return;
      // }
      // 明細が無い場合はエラー
      if (state.item.shopContractDetailList.length == 0) {
        alert("明細情報がありません。追加して下さい。");
        return;
      }

      const params = {
        id: state.item.id,
        user_id: state.item.user_id,
        shop_id: state.item.shop_id,
        start_date: state.item.start_date,
        end_date:
          state.item.end_date == null
            ? setting.auto_flag_end_date
            : state.item.end_date,
        description: state.item.description,
        auto_flg: state.item.auto_flg,
      };

      // 期間チェック
      const check_result = await shopContractsRepository
        .admin_check(params)
        .then((response) => {
          if (response.data) {
            return response.data.result;
          }
        })
        .catch((error) => {
          throw "ERROR:shopContractsRepository.admin_create (" + error + ")";
        });
      if (!check_result) {
        alert("他の契約と契約期間が重複しています。");
        return;
      }

      if (!window.confirm("保存してよろしいですか？")) {
        return false;
      }

      // ローディング表示
      store.dispatch("loadingIcon/showIcon");

      // 契約情報を保存・更新してから、明細情報を保存する。
      if (new_flg) {
        // 新規
        await shopContractsRepository
          .admin_create(params)
          .then((response) => {
            if (response.data) {
              // データ追加OK
              state.item.id = response.data.id; // idをセット
            }
          })
          .catch((error) => {
            throw "ERROR:shopContractsRepository.admin_create (" + error + ")";
          });
      } else {
        // 更新
        await shopContractsRepository
          .admin_update(params)
          .then((response) => {
            if (response.data) {
              // データ追加OK
            }
          })
          .catch((error) => {
            throw "ERROR:shopContractsRepository.admin_update (" + error + ")";
          });
      }

      // 明細情報を保存する
      // 明細情報を保存する前に、既存の明細情報を一旦削除し、契約情報のIDを明細情報にセットする。
      if (!new_flg) {
        await shopContractDetailsRepository
          .admin_all_delete(state.item.id)
          .then((response) => {
            if (response.data) {
              // データ削除OK
            }
          })
          .catch((error) => {
            throw (
              "ERROR:shop_contract_detailsRepository.admin_all_delete (" +
              error +
              ")"
            );
          });
      }
      for (
        let index = 0;
        index < state.item.shopContractDetailList.length;
        index++
      ) {
        const detail = state.item.shopContractDetailList[index];
        detail.shop_contract_id = state.item.id;
        const params = {
          id: 0,
          shop_contract_id: detail.shop_contract_id,
          plan_code: detail.plan_code,
          initial_fee: detail.initial_fee,
          fixed_fee: detail.fixed_fee,
          quantity: detail.quantity,
          sort: index + 1,
        };
        await shopContractDetailsRepository
          .admin_create(params)
          .then((response) => {
            if (response.data) {
              // データ追加OK
              detail.id = response.data.id; // idをセット
            }
          })
          .catch((error) => {
            throw (
              "ERROR:shopContractDetailsRepository.admin_create (" + error + ")"
            );
          });
      }

      // 未請求契約の場合、未請求契約情報を追加・更新する
      // 新規登録で、現在の締め日＋1ヶ月以内の開始日を持つ契約、
      // もしくは、更新で、現在の締め日＋1ヶ月以降の開始日が、現在の締め日＋1ヶ月以内の開始日に変更された場合
      let unclaimedContractUpdateFlg = false;
      const base_date = new Date(state.closeDate);
      base_date.setDate(1);
      base_date.setMonth(base_date.getMonth() + 2);
      // billing_month用に締め日＋2ヶ月の年月を取得しておく
      const tmpBillingMonth = `${base_date.getFullYear()}${(
        "0" +
        (base_date.getMonth() + 1)
      ).slice(-2)}`;
      // 締め日＋1ヶ月の末尾を取得
      base_date.setDate(0);

      console.log("base_date", utilDate.getDateFormatLong(base_date));
      console.log("state.item.start_date", state.item.start_date);

      // 新規契約で現在の締め日＋1ヶ月以内の開始日か判定
      if (new_flg) {
        if (state.item.start_date <= utilDate.getDateFormatLong(base_date)) {
          unclaimedContractUpdateFlg = true;
        }
      } else {
        // 更新で、現在の締め日＋1ヶ月以降の開始日が、現在の締め日＋1ヶ月以内の開始日に変更された場合
        if (
          state.prevItem.start_date > utilDate.getDateFormatLong(base_date) &&
          state.item.start_date <= utilDate.getDateFormatLong(base_date)
        ) {
          // 更新契約の場合
          unclaimedContractUpdateFlg = true;
        }
      }

      if (unclaimedContractUpdateFlg) {
        // 未請求契約情報を追加・更新する
        const [sYear, sMonth, sDay] = state.item.start_date.split("-");
        const saveParams = {
          shop_contract_id: state.item.id,
          billing_month: Number(tmpBillingMonth),
          service_month: Number(`${sYear}${sMonth}`),
        };
        // console.log(saveParams);
        await unclaimedContractsRepository
          .admin_save(saveParams)
          .then((response) => {
            if (response.data) {
              // データ追加OK
            }
          })
          .catch((error) => {
            throw "ERROR:unclaimedContractsRepository.save (" + error + ")";
          });
      }

      // shopテーブルの契約情報を更新する
      await updateShopValid(state.item.shop_id);

      // ローディング非表示
      store.dispatch("loadingIcon/hideIcon");

      cancelDialog();

      ctx.emit("updated", state.item);
    };

    // ダイアログキャンセルボタン
    const cancelDialog = () => {
      state.dialog = false;
      ctx.refs.observer.reset(); // 入力エラー表示クリア
      clearDialog();
    };

    // 保存処理
    const onSubmitDetail = async () => {
      // 入力チェック エラー状態の部品が無いか調べる
      const isValid = await ctx.refs.detailObserver.validate();

      if (!isValid) {
        // エラー処理
        return;
      } else {
        // 同じプランがあるかチェック
        const checkCode = state.detailItem.plan_code.split("_");
        const sameCode = state.item.shopContractDetailList.find((x) => {
          if (x.plan_code.split("_")[0] == checkCode[0]) {
            return true;
          } else {
            return false;
          }
        });
        if (sameCode) {
          if (
            !window.confirm(
              "同じプラン、又は、同じ系統のプランが既に登録されていますが、追加してよろしいですか？"
            )
          ) {
            return false;
          }
        }

        state.detailItem.total_fixed_fee =
          state.detailItem.fixed_fee * state.detailItem.quantity;
        const plan = state.planList.find(
          (x) => x.code === state.detailItem.plan_code
        );
        state.detailItem.plan_name = plan.name;
        state.detailItem.plan_type_name = plan.type_label;
        const rowItem = {};
        Object.assign(rowItem, state.detailItem);
        // rowItem.plan_type = setting.plan_type_label.find(
        //   (x) => x.value === plan.code.slice(0, 2)
        // ).text;

        // 新規か更新か
        const detail = state.item.shopContractDetailList.find(
          (x) => x.random_id === state.detailItem.random_id
        );
        if (detail) {
          // 更新
          set(
            state.item.shopContractDetailList,
            state.item.shopContractDetailList.indexOf(detail),
            rowItem
          );
        } else {
          // リストに明細を追加
          state.item.shopContractDetailList.push(rowItem);
        }
      }

      cancelDetailDialog();
    };

    const editDetailItem = (detailItem) => {
      Object.assign(state.detailItem, detailItem);
      state.detailDialog = true;
    };

    const deleteDetailItem = (detailItem) => {
      if (!window.confirm("明細を削除してよろしいですか？")) {
        return false;
      }
      const detail = state.item.shopContractDetailList.find(
        (x) => x.random_id === detailItem.random_id
      );
      if (detail) {
        // 更新
        state.item.shopContractDetailList.splice(
          state.item.shopContractDetailList.indexOf(detail),
          1
        );
      }
    };

    // ダイアログキャンセルボタン
    const cancelDetailDialog = () => {
      state.detailDialog = false;
      ctx.refs.detailObserver.reset(); // 入力エラー表示クリア
      clearDetailDialog();
    };

    // プラン変更
    const onChangePlan = () => {
      const plan = state.planList.find(
        (x) => x.code === state.detailItem.plan_code
      );
      if (plan) {
        state.detailItem.plan_name = plan.name;
        state.detailItem.plan_type_name = plan.type_label;
        state.detailItem.initial_fee = plan.default_initial_fee;
        state.detailItem.fixed_fee = plan.default_fixed_fee;
        state.detailItem.quantity = plan.default_quantity;
      }
    };

    const init = async () => {
      // 初期化
      clearDialog();
      clearDetailDialog();

      // 締め日を取得
      state.closeDate = await systemPropsRepository
        .admin_get_key("closing_date")
        .then((response) => {
          if (response.data) {
            return response.data.value;
          } else {
            return "";
          }
        })
        .catch((error) => {
          throw (
            "ERROR:index.vue/init systemPropsRepository.admin_get_key (" +
            error +
            ")"
          );
        });
      // 開始日のmin用に締め日の翌日をセット
      const plus = new Date(state.closeDate);
      plus.setDate(plus.getDate() + 1);
      state.closeDatePlusOne = utilDate.getDateFormatLong(plus);

      // Planリスト取得
      state.planList.splice(0);
      state.planSelectList.splice(0);
      await plansRepository
        .admin_list()
        .then((response) => {
          if (response.data) {
            // データ追加OK
            Object.keys(response.data).forEach(function (key) {
              const plan = response.data[key];
              const type_label = setting.plan_type_label.find(
                (x) => x.value === plan.code.slice(0, 2)
              ).text;

              set(plan, "type_label", type_label);
              state.planList.push(plan);

              const selectItem = {
                value: plan.code,
                text: type_label + " : " + plan.name,
              };
              state.planSelectList.push(selectItem);
            });
          }
        })
        .catch((error) => {
          throw (
            "ERROR:dialogComponent.vue/onSubmit templateRepository.create (" +
            error +
            ")"
          );
        });
    };

    init();

    const onChangeAutoFlg = () => {
      if (state.item.auto_flg) {
        state.item.end_date = null;
      }
    };

    const updateShopValid = async (shop_id) => {
      const params = {
        shop_id: shop_id,
        base_date: utilDate.getDateFormatLong(new Date()),
      };
      await shopsRepository
        .admin_update_valid(params)
        .then((response) => {
          if (response.data) {
            // データ追加OK
          }
        })
        .catch((error) => {
          throw (
            "ERROR:contractDialogComponent.vue/updateShopValid shopsRepository.admin_update_valid (" +
            error +
            ")"
          );
        });
    };

    // 合計料金の計算
    watch(state.item.shopContractDetailList, (newValue, oldValue) => {
      let tmp_total_fee = 0;
      Object.keys(newValue).forEach(function (key) {
        tmp_total_fee += newValue[key].total_fixed_fee;
      });
      state.item.total_fee = tmp_total_fee;
    });

    // 返却オブジェクト定義
    return {
      setting,
      ...toRefs(state),
      editDialog,
      onSubmit,
      cancelDialog,
      onSubmitDetail,
      cancelDetailDialog,
      showDialog,
      showDetailDialog,
      onChangePlan,
      editDetailItem,
      deleteDetailItem,
      onChangeAutoFlg,
    };
  },
});
</script>

<style scoped>
::v-deep #shop_contract_detail thead {
  background-color: #fff3e0 !important;
}
::v-deep .td_plan_type_name {
  min-width: 110px;
  width: 150px;
}
::v-deep .td_plan_name {
  min-width: 150px;
  width: 250px;
}
::v-deep .td_initial_fee,
::v-deep .td_fixed_fee,
::v-deep .td_quantity,
::v-deep .td_total_fixed_fee {
  min-width: 100px;
  width: 100px;
}
</style>
