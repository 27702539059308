import Repository from "../repository";

const resource = "/shops";

export default {
  list(payload) {
    return Repository.get(`${resource}/list`, { params: payload });
  },
  get(shopId) {
    return Repository.get(`${resource}/get/${shopId}`);
  },
  get_with_prop(shopId) {
    return Repository.get(`${resource}/get_with_prop/${shopId}`);
  },
  get_with_prop_valid_option(shopId) {
    return Repository.get(`${resource}/get_with_prop_valid_option/${shopId}`);
  },
  create(payload) {
    return Repository.post(`${resource}/create`, payload);
  },
  update(payload) {
    return Repository.put(`${resource}/update`, payload);
  },
  delete(shopId) {
    return Repository.delete(`${resource}/delete/${shopId}`);
  },
  admin_list(payload) {
    return Repository.get(`${resource}/admin/list`, { params: payload });
  },
  admin_get(shopId) {
    return Repository.get(`${resource}/admin/get/${shopId}`);
  },
  admin_create(payload) {
    return Repository.post(`${resource}/admin/create`, payload);
  },
  admin_update(payload) {
    return Repository.put(`${resource}/admin/update`, payload);
  },
  admin_delete(shopId) {
    return Repository.delete(`${resource}/admin/delete/${shopId}`);
  },
  admin_check(payload) {
    return Repository.post(`${resource}/admin/check`, payload);
  },
  admin_get_contract(shopId) {
    return Repository.get(`${resource}/admin/get_contract/${shopId}`);
  },
  admin_update_valid(payload) {
    return Repository.post(`${resource}/admin/update_valid`, payload);
  },
};
